(() => {
  const mainMenu = document.getElementById('mainMenu');
  const items = document.querySelectorAll('#mainMenu .menuItem');
  const menuIcon = document.querySelectorAll('#mainMenu .menuIcon')[0];
  const pathList = location.pathname.split('/');
  const page = `/${pathList[pathList.length - 1].replace('.html', '')}`;
  const activePageNode = document.querySelector(`[href="${page}.html"]`);
  const getStartedButton = mainMenu.querySelector(`.button.red.menuButton`);

  menuIcon.onclick = () => {
    if(mainMenu.classList.contains('active')) {
      mainMenu.classList.remove('active');
    } else {
      mainMenu.classList.add('active');
    }
  };

  const switchDeviceMenuView = () => {
    const logo = document.querySelector('.header .top .logo');
    const menuNode = document.getElementById('mainMenu');
    const isDesktop = menuNode.classList.contains('desktop');

    if(this.innerWidth < 800 && isDesktop) {
      menuNode.classList.remove('desktop');
      logo.classList.remove('desktop');
      menuNode.classList.add('mobile');
      logo.classList.add('mobile');
    } else if(this.innerWidth >= 800 && !isDesktop) {
      menuNode.classList.add('desktop');
      logo.classList.add('desktop');
      menuNode.classList.remove('mobile');
      logo.classList.remove('mobile');
    }
  };

  const handleClick = (items, { currentTarget }) => {
    const isActive = currentTarget.classList.contains('active');
    items.forEach((item) => item.classList.remove('active'));

    if(isActive) {
      currentTarget.classList.remove('active');
    } else {
      currentTarget.classList.add('active');
    }
  };

  if(location.pathname.includes('/pilot')) {
    getStartedButton.remove();
  }

  items.forEach((item) => item.onclick = (e) => e.stopPropagation());
  items.forEach((item) => item.onclick = handleClick.bind(null, items));
  window.onresize = switchDeviceMenuView;
  switchDeviceMenuView();

  if(activePageNode) {
    activePageNode.classList.add('active');
    if(activePageNode.closest('.withSubmenu')) {
      activePageNode.closest('.withSubmenu').classList.add('active');
    }
  }
})();
